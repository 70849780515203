import styled from "styled-components";
import shouldForwardProp from "@styled-system/should-forward-prop";

import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  border,
  background,
  position,
} from "styled-system";

const Box = styled("div", { shouldForwardProp })(
  {
    boxSizing: "border-box",
    margin: 0,
    minWidth: 0,
  },
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    background,
    border,
    position
  )
);

Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...typography.propTypes,
  ...layout.propTypes,
  ...background.propTypes,
  ...flexbox.propTypes,
  ...border.propTypes,
  ...position.propTypes,
};

export default Box;
