import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import Box from "./box";
import Flex from "./flex";
import Wrapper from "./wrapper";
import Helmet from "react-helmet";

import LogoType from "../images/logotype";

const Mask = styled(Box)`
  background-color: #44524b;
  width: 100%;
  height: 100vh;
`;

const Landing = styled(Box)`
  width: 100%;
  height: 100%;
  color: #fafaf7;
  position: absolute;
`;

const Layout = ({ children, position, theme, bg }) => {
  const [showIntro, setIntro] = useState(true);
  const logoControls = useAnimation();
  const maskControls = useAnimation();
  const panelControls = useAnimation();

  // This runs when the page is loaded.
  useEffect(() => {
    if (sessionStorage.getItem("showintro")) {
      setIntro(false);
    }
  }, []);

  const handleShow = () => {
    setIntro(false);
    sessionStorage.setItem("showintro", "true");
  };

  const sequence = async () => {
    await logoControls.start("visible");
    await logoControls.start("hidden");
    await maskControls.start("hidden");
    return await panelControls.start({
      transitionEnd: { display: "none" },
    });
  };
  useEffect(() => {
    sequence();
  }, []);

  const logoVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", bounce: 0, duration: 1, delay: 0.5 },
    },
    hidden: {
      opacity: 0,
      y: "15%",
      transition: { type: "spring", bounce: 0, duration: 1, delay: 0.5 },
    },
  };
  const maskVariants = {
    initial: {
      y: 0,
    },
    hidden: {
      y: "-100%",
      transition: { type: "spring", bounce: 0, duration: 1.2, delay: 0.5 },
    },
  };

  return (
    <Box bg={bg} minHeight="100vh">
      {showIntro && (
        <motion.div
          animate={panelControls}
          onAnimationComplete={(definition) => {
            handleShow();
          }}
        >
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100vh"
            zIndex="99999"
          >
            <motion.div>
              <Landing>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  height="100vh"
                  py={3}
                >
                  <Wrapper py={5}>
                    <Box width={2 / 3} mx="auto">
                      <motion.div
                        animate={logoControls}
                        initial="hidden"
                        variants={logoVariants}
                      >
                        <LogoType />
                      </motion.div>
                    </Box>
                  </Wrapper>
                </Flex>
              </Landing>
            </motion.div>
            <motion.div
              animate={maskControls}
              initial="initial"
              variants={maskVariants}
            >
              <Mask />
            </motion.div>
          </Box>
        </motion.div>
      )}
      <Header theme={theme} position={position} />
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://lamaisonmere.com",
          "name": "La Maison Mère",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "4220 rue Augustin Frigon #249",
            "addressLocality": "Montreal",
            "addressRegion": "QC",
            "postalCode": "H1Y 0C4",
            "addressCountry": "CA"
          }
        }
      `}
        </script>
      </Helmet>
      <motion.main
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        {children}
      </motion.main>
      <Footer theme={theme} />
    </Box>
  );
};

export default Layout;
