import styled from "styled-components";
import { flexbox, background } from "styled-system";
import Box from "./box";

const Flex = styled(Box)(
  {
    display: "flex",
  },
  flexbox,
  background
);

export default Flex;
