import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import Flex from "./flex";
import Box from "./box";
import Wrapper from "./wrapper";
import Logo from "../images/logo";

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin-left: 1.5em;
  font-size: 18px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: 1000;
    bottom: -3px;
    left: 0;
    background: currentColor;
    height: 1px;
    width: 0%;
    display: block;
    transition: width 0.3s ease;
  }
  &:hover,
  &.active {
    &:after {
      width: 100%;
    }
  }
  @media all and (max-width: 768px) {
    font-size: 16px;
  }
`;

const HeaderBox = styled(Box)`
  position: ${(props) => (props.position === "sticky" ? "sticky" : "static")};
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) =>
    props.theme === "dark" ? "#fafaf7" : "transparent"};

  ${NavLink} {
    color: ${(props) => (props.theme === "dark" ? "black" : "#fafaf7")};
  }
  svg {
    color: ${(props) => (props.theme === "dark" ? "black" : "#fafaf7")};
  }
`;

const Header = ({ theme, position }) => {
  const { languages, changeLanguage, language } = useI18next();
  return (
    <HeaderBox theme={theme} position={position}>
      <Wrapper>
        <Flex
          as="header"
          justifyContent="space-between"
          alignItems="center"
          py={3}
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >
              <Logo />
            </Link>
          </h1>
          <Box>
            <NavLink to="/artistes" activeClassName="active" partiallyActive>
              {language && language === "en" ? "Artists" : "Artistes"}
            </NavLink>
            <NavLink to="/spectacles" activeClassName="active" partiallyActive>
              {language && language === "en" ? "Shows" : "Spectacles"}
            </NavLink>
            <NavLink to="/contact" activeClassName="active" partiallyActive>
              {language && language === "en" ? "Contact" : "Contact"}
            </NavLink>

            {languages.map(
              (lng) =>
                language !== lng && (
                  <NavLink
                    to="#"
                    key={lng}
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage(lng);
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {lng}
                  </NavLink>
                )
            )}
          </Box>
        </Flex>
      </Wrapper>
    </HeaderBox>
  );
};

export default Header;
