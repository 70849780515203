import * as React from "react";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64.361}
    height={63.37}
    fill="currentColor"
    {...props}
  >
    <path
      data-name="Path 44"
      d="M64.253 0h-8.422L32.312 27.471 8.793 0H.218l-.219 53.486v9.163a.721.721 0 0 0 .721.721h11.267a.721.721 0 0 0 .721-.721V48.72a5.514 5.514 0 0 1 11.028 0v13.927a.721.721 0 0 0 .721.721h14.848a.721.721 0 0 0 .721-.721V48.72a5.514 5.514 0 0 1 11.028 0v13.927a.721.721 0 0 0 .721.721h11.861a.721.721 0 0 0 .721-.721v-9.163ZM11.266 61.928H1.442v-3.139h9.824Zm0-4.582H1.442v-3.139h9.824Zm0-4.582H1.442v-3.139h9.824Zm12.472-8.247a7.017 7.017 0 0 0-.611-.693 6.955 6.955 0 0 0-9.807 0 7.049 7.049 0 0 0-.611.693v-.378a5.514 5.514 0 0 1 11.028 0v.018Zm0-4.583a6.964 6.964 0 0 0-11.03 0v-.377a5.514 5.514 0 0 1 11.028 0v.035Zm0-4.582-.016-.021c-.037-.048-.075-.1-.114-.143l-.016-.02c-.044-.053-.089-.1-.134-.157l-.038-.042a5.882 5.882 0 0 0-.106-.117l-.041-.044c-.048-.05-.1-.1-.146-.149a6.908 6.908 0 0 0-4.73-2.021h-.346a6.909 6.909 0 0 0-4.73 2.021c-.05.049-.1.1-.146.149l-.041.044a5.882 5.882 0 0 0-.106.117l-.038.042c-.045.052-.09.1-.134.157l-.015.02a5.097 5.097 0 0 0-.114.143l-.016.021v-.377a5.514 5.514 0 0 1 11.028 0v.035Zm14.848 26.574H25.18v-3.139h13.407Zm0-4.582H25.18v-3.139h13.407Zm0-4.582H25.18v-3.139h13.407Zm12.472-8.247a7.081 7.081 0 0 0-.611-.693 6.956 6.956 0 0 0-9.807 0 7.081 7.081 0 0 0-.611.693v-.378a5.514 5.514 0 0 1 11.028 0v.019Zm0-4.583a7.014 7.014 0 0 0-.611-.692 6.956 6.956 0 0 0-9.807 0 6.983 6.983 0 0 0-.611.692v-.377a5.514 5.514 0 0 1 11.028 0v.035Zm0-4.582-.017-.021c-.037-.048-.075-.1-.114-.143l-.016-.02c-.044-.053-.089-.1-.134-.157l-.038-.042a5.882 5.882 0 0 0-.106-.117l-.041-.044c-.048-.05-.1-.1-.146-.149a6.909 6.909 0 0 0-4.73-2.021h-.346a6.909 6.909 0 0 0-4.73 2.021c-.049.049-.1.1-.145.149l-.043.045-.1.114-.04.044c-.045.052-.09.1-.133.156l-.017.021a5.018 5.018 0 0 0-.113.142l-.017.021v-.377a5.528 5.528 0 0 1 3.019-4.877 5.5 5.5 0 0 1 3.732-.46q.133.031.263.068l.13.039a5.445 5.445 0 0 1 .626.238l.121.056a5.529 5.529 0 0 1 3.138 4.937v.033Zm11.86 26.574H52.5v-3.139h10.418Zm0-4.582H52.5v-3.139h10.418Zm0-4.582H52.5v-3.139h10.418Z"
    />
  </svg>
);

export default Logo;
