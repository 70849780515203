import React from "react";
import styled from "styled-components";
import Box from "./box";
import Flex from "./flex";
import Wrapper from "./wrapper";
import LogoType from "../images/logotype";
import Facebook from "../images/facebook";
import Instagram from "../images/instagram";

const FooterBox = styled(Box)`

border-top: ${(props) =>
  props.theme === "dark"
    ? "1px solid rgba(0,0,0,0.1);"
    : "1px solid rgba(255,255,255,0.1);"}

color: ${(props) =>
  props.theme === "dark" ? "rgba(0,0,0,0.25);" : "rgba(255,255,255,0.25);"}
`;

const Footer = ({ theme }) => {
  return (
    <FooterBox py={5} fontSize="14px" mt="3vw" theme={theme}>
      <Wrapper>
        <Flex justifyContent="space-between" width={1} flexWrap="wrap">
          <Box width={[1, 1 / 2]} mb={3}>
            <Box maxWidth="250px" mx={["auto", 0]}>
              <LogoType />
            </Box>
          </Box>
          <Flex alignItems="center" width={[1, "auto"]} justifyContent="center">
            <Box
              as="a"
              p={3}
              href="https://www.facebook.com/lamaisonmereproductions"
            >
              <Facebook />
            </Box>
            <Box
              p={3}
              as="a"
              href="https://www.instagram.com/lamaisonmereproductions/"
            >
              <Instagram />
            </Box>
            <Box p={3}>
              <a href="mailto:info@lamaisonmere.com">info@lamaisonmere.com</a>
            </Box>
          </Flex>
        </Flex>
      </Wrapper>
    </FooterBox>
  );
};

export default Footer;
