import * as React from "react";

const Facebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.061}
    height={38.061}
    fill="currentColor"
    {...props}
  >
    <path d="M19.03 0A19.031 19.031 0 0 0 0 19.031a19.03 19.03 0 0 0 19.03 19.03 19.03 19.03 0 0 0 19.031-19.03A19.031 19.031 0 0 0 19.03 0Zm5.31 11.559h-2.507c-.886 0-1.07.362-1.07 1.278v2.212h3.577l-.345 3.884h-3.232v11.6h-4.632V18.982h-2.41v-3.933h2.41v-3.1c0-2.906 1.554-4.424 5-4.424h3.208Z" />
  </svg>
);

export default Facebook;
