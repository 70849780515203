import React from "react";
import Box from "./box";

const Wrapper = (props) => (
  <Box width={1} maxWidth={"1400px"} mx="auto" px={[3, 3, 3, 3, 0]} {...props}>
    {props.children}
  </Box>
);

export default Wrapper;
