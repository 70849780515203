import * as React from "react";

const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.061}
    height={38.061}
    fill="currentColor"
    {...props}
  >
    <path
      data-name="Path 52"
      d="M19.03 15.549a3.478 3.478 0 1 0 3.478 3.478 3.478 3.478 0 0 0-3.478-3.478Z"
    />
    <path
      data-name="Path 53"
      d="M27.164 12.877a3.449 3.449 0 0 0-1.98-1.98 5.782 5.782 0 0 0-1.937-.36c-1.1-.05-1.431-.06-4.217-.06s-3.116.01-4.216.06a5.79 5.79 0 0 0-1.938.36 3.449 3.449 0 0 0-1.98 1.98 5.764 5.764 0 0 0-.359 1.938c-.051 1.1-.061 1.43-.061 4.216s.01 3.116.061 4.216a5.764 5.764 0 0 0 .359 1.938 3.449 3.449 0 0 0 1.98 1.98 5.789 5.789 0 0 0 1.938.36c1.1.05 1.43.06 4.216.06s3.116-.01 4.217-.06a5.781 5.781 0 0 0 1.937-.36 3.449 3.449 0 0 0 1.98-1.98 5.783 5.783 0 0 0 .36-1.938c.05-1.1.061-1.43.061-4.216s-.011-3.116-.061-4.216a5.782 5.782 0 0 0-.36-1.938ZM19.03 24.389a5.358 5.358 0 1 1 5.358-5.358 5.358 5.358 0 0 1-5.358 5.358Zm5.57-9.676a1.252 1.252 0 1 1 1.252-1.252 1.252 1.252 0 0 1-1.252 1.252Z"
    />
    <path
      data-name="Path 54"
      d="M19.03 0A19.031 19.031 0 0 0 0 19.031a19.03 19.03 0 0 0 38.061 0A19.032 19.032 0 0 0 19.03 0Zm10.372 23.333a7.665 7.665 0 0 1-.485 2.533 5.341 5.341 0 0 1-3.052 3.052 7.692 7.692 0 0 1-2.533.485c-1.113.05-1.468.062-4.3.062s-3.189-.012-4.3-.062a7.692 7.692 0 0 1-2.533-.485 5.336 5.336 0 0 1-3.051-3.052 7.64 7.64 0 0 1-.485-2.533c-.051-1.113-.063-1.468-.063-4.3s.012-3.189.063-4.3a7.64 7.64 0 0 1 .485-2.533 5.336 5.336 0 0 1 3.051-3.052 7.692 7.692 0 0 1 2.533-.485c1.113-.05 1.468-.063 4.3-.063s3.189.013 4.3.063a7.692 7.692 0 0 1 2.533.485 5.341 5.341 0 0 1 3.052 3.052 7.665 7.665 0 0 1 .485 2.533c.051 1.113.063 1.468.063 4.3s-.012 3.187-.063 4.3Z"
    />
  </svg>
);

export default Instagram;
